import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./src/redux/reducers";

const loadDevTools = () => {
  const nodeEnv =
    process.env.REACT_NODE_ENV &&
      process.env.REACT_NODE_ENV.includes("-")
      ? process.env.REACT_NODE_ENV.split("-")[0]
      : process.env.REACT_NODE_ENV;
  return nodeEnv === "development" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;
};

const initialState = {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
  devTools: loadDevTools(),
});

const ReduxProvider = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

export default ReduxProvider;
