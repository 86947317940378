import { types } from "../constants"
const initialState = {
  toggleCollapsedNav: true,
  logo: ""
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_NAV:
      return {
        ...state,
        toggleCollapsedNav: !action.toggleCollapsedNav,
      }
    case types.LOGO:
      return {
        ...state,
        logo: action.logo,
      }
    default:
      return state
  }
}

export default commonReducer;